@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #fff;
}
body {
  height: fit-content;
  font-family: "Inter", sans-serif;
}
.hamburger-menu {
  transition: transform 1.3s ease-in-out;
  transform: translateX(-100%);
}

.hamburger-menu.visible {
  transform: translateX(0);
}

.overlay {
  transition: opacity 1.3s ease-in-out;
}

.overlay.visible {
  opacity: 1;
}
